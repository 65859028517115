<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-btn v-if="rightFilter('w')" icon  :disabled="!canEdit" @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn v-if="rightFilter('w')" icon  :disabled="!item || !canEdit" @click="edit">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="rightFilter('d')"
          icon
           :disabled="!canEdit"
          @click="dialogDelete = true"
        >
          <v-icon>remove</v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <ProgramLimitHistory
          v-if="!byInsured"
          :item="vm.selected"
        ></ProgramLimitHistory>

        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container v-if="addItem">
                <v-row>
                  <v-col v-if="!addItem.id" cols="12">
                    <v-autocomplete
                      v-model="addItem.serviceCategoryId"
                      :items="addItem.toAddItems"
                      :label="'Категория услуг'"
                      item-text="name"
                      item-value="id"
                      required
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col v-else cols="12">
                    <v-text-field
                      v-model="addItem.serviceCategory"
                      label="Категория услуг"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="byInsured && addItem.limitType === 'PC'"
                    cols="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-model="addItem.limitCategoryKey"
                      :items="dictTypes"
                      :label="'Тип ограничения'"
                      item-text="label"
                      item-value="key"
                      readonly

                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col v-else cols="12" md="12">
                    <v-autocomplete
                      v-model="addItem.limitCategoryKey"
                      :items="dictTypes"
                      :label="'Тип ограничения'"
                      item-text="label"
                      item-value="key"

                      clearable
                      @change="limitServices"


                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="!byInsured" cols="12" md="12">
                    <v-autocomplete
                      v-model="addItem.unionId"
                      :items="$parent.unions.items"
                      :label="'Объединение'"
                      item-text="number"
                      item-value="id"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col v-else cols="12" md="12">
                    <v-autocomplete
                      v-model="addItem.unionId"
                      :items="$parent.unions.items"
                      :label="'Объединение'"
                      item-text="number"
                      item-value="id"
                      clearable
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="addItem.amount"
                      label="Сумма"
                      type="number"
                      :clearable="!addItem.unionId"
                      :readonly="!!addItem.unionId"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="addItem.quantity"
                      label="Количество"
                      type="number"
                      :clearable="!addItem.unionId"
                      :readonly="!!addItem.unionId"


                    >

                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">
                Отмена
              </v-btn>
              <SaveButton
                color="primary"
                content="OK"
                :handler="save"
              >

              </SaveButton>

            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
            >Вы точно хотите удалить категорию?
            </v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogDelete = false">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        sort-by="unionNumber"
        sort-desc
        @click:row="rowClick"
      >
      </v-data-table>
    </div>
    <div v-if="item" class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="searchService"
          hide-details
          prepend-inner-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
        ></v-text-field>
        <v-spacer/>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headersServices"
        :items="filteredServices"
        :items-per-page="-1"
        item-key="serviceId"
        hide-default-footer
        :loading="servicesLoading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            :loading="item.loading"
            :disabled="item.loading"
            icon
            @click="item.action()"
          >
            <v-icon v-if="!item.id">
              check_box_outline_blank
            </v-icon>
            <v-icon v-else color="primary">
              check_box
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.quantity="props">
          <v-edit-dialog
            :return-value.sync="props.item.quantity"
            large
            cancel-text="Отмена"
            save-text="Ок"
            @save="props.item.save"
          >
            <div>{{ props.item.quantity }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.quantity"
                label="Количество"
                single-line
                autofocus
                clearable
                type="number"

              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
  import fetch from "@/utils/fetch";
  import dictFilter from "@/filters/dict.filter";
  import vm2js from "@/utils/vm2js";
  import js2vm from "@/utils/js2vm";
  import ctx from "@/ctx";
  import rightFilter from "../../filters/right.filter";
  import {limitToIds} from "@/utils/staticData";
  import SaveButton from "@/components/ButtonFacade";
  import ProgramLimitHistory from "@/components/programs/ProgramLimitHistory";

  let Item = function (d, program, vm, byInsured = false) {
    let self = this;


    self.programId = (byInsured) ? null : program.selected;
    self.toAddItems = [];

    function getToAddItems() {
      if (!vm) return;
      ctx.src.categories.items.forEach(c => {
        const exist = vm.items.find(e => {
          return e.serviceCategoryId === c.id;
        });
        if (!exist) self.toAddItems.push(c);
      });
    }

    getToAddItems();

    d.serviceCategory = d.serviceCategoryId;
    d.limitCategoryLabel = d.limitCategoryKey;
    d.unionNumber = d.unionId;
    d.amount = d.unionId ? d.unionId : d.amount;


    self.amount2vm = function (val) {
      if (!d.unionId) return val;
      const union = program.unions.items.find(u => {
        return u.id === val;
      });
      if (union) return union.number;
      return undefined;
    };

    self.serviceCategory2vm = function (val) {
      if (!val) return undefined;
      return ctx.src.categories.get(val).name;
    };
    self.limitCategoryLabel2vm = function (val) {
      if (!val) return undefined;
      return dictFilter("INS_PROGRAM_CAT_TYPE", val);
    };
    self.unionNumber2vm = function (val) {
      const union = program.unions.items.find(u => {
        return u.id === val;
      });
      if (union) return union.number;
      return undefined;
    };

    js2vm(self, d);

    if (d.unionId) {
      const union = program.unions.items.find(u => {
        return u.id === d.unionId;
      });
      if (union) {
        self.amount = union.amount;
        self.quantity = union.quantity;
      }
      else {
        self.amount = undefined;
        self.quantity = undefined;
      }
    }
  };

  let Service = function (d, vm) {
    let self = this;
    self.quantity = undefined;
    self.loading = false;

    self.action = function () {
      if (self.id) return self.delete();
      else return self.save();
    };
    self.save = async function () {
      if (!vm.byInsured) {
        self.loading = true;
        const res = await fetch.post(
          "/api/insurance/programs/save-limit",
          vm2js(self)
        );
        if (res) self.id = res;
        self.loading = false;
      } else {
        let data = vm2js(self);
        data.insuredId = vm.vm.selected;
        const res = await fetch.post(
          "/api/insurance/programs/save-limit-by-insured",
          data
        );
        if (res) {
          Object.assign(self, res);
        }
      }
    };
    self.delete = async function () {
      self.loading = true;
      const res = await fetch.post(
        "/api/insurance/programs/delete-limit?id=" + self.id
      );
      if (res) {
        self.quantity = undefined;
        self.id = undefined;
      }
      self.loading = false;
    };

    js2vm(self, d);
  };

  export default {
    components: {SaveButton, ProgramLimitHistory},
    props: {
      vm: Object,
      byInsured: {type: Boolean, default: false}
    },
    data: () => ({
      ctx,
      rightFilter,
      currentPage: 0,
      totalPages: 0,
      loading: false,
      selected: [],
      dictTypes4Contract: [{key: "M", label: "Только основной застрахованный"}],
      dictTypes: [
        {key: "M", label: "Только основной застрахованный"},
        {key: "N", label: "Доп. пакет"},
        {key: "I", label: "Индивидуальный"}
      ],
      headers: [
        {
          text: "Категория услуги",
          align: "start",
          value: "serviceCategory"
        },
        {
          text: "Тип ограничения",
          sortable: false,
          value: "limitCategoryLabel"
        },
        {
          text: "Количество",
          sortable: false,
          value: "quantity"
        },
        {text: "Номер объединения", value: "unionNumber"},
        {text: "Сумма", sortable: false, value: "amount"}
      ],
      headersServices: [
        // { text: "Включить", value: "actions", sortable: false },
        {
          text: "Услуга",
          align: "start",
          value: "name"
        },
        {
          text: "Количество",
          sortable: false,
          value: "quantity"
        }
      ],
      items: [],
      item: undefined,
      dialog: false,
      dialogDelete: false,
      addItem: undefined,
      services: [],
      servicesLoading: false,
      searchService: undefined
    }),
    watch: {
      "$parent.selected": function (val) {
        this.selected = [];
        this.items = [];
        this.item = undefined;
        if (val) return this.fetchItems(val);
      },
      "$parent.unions.items": function (val) {
        if (!val || val.length === 0) return;
        this.items.forEach(i => {
          if (i.unionId) {
            const union = val.find(u => {
              return u.id === i.unionId;
            });
            if (union) {
              i.amount = union.amount;
              i.unionNumber = union.number;
            } else {
              i.amount = undefined;
              i.unionNumber = undefined;
            }
          }
        });
      },
      selected: function (val) {
        if (val.length === 0) this.item = undefined;
        else {
          this.item = val[0];
        }
      },
      item: function (val) {
        if (!val) return;
        this.fetchServices();
      },

    },
    computed: {

      canEdit() {
        return this.byInsured || this.$store.getters["isContractEditable"];
      },
      formTitle() {
        return this.addItem && !this.addItem.id
          ? "Новая запись"
          : "Редактирование записи";
      },
      filteredServices() {
        if (!this.searchService || 0 === this.searchService.length)
          return this.services;
        return _.orderBy(
          this.services.filter(item => {
            return (
              item.name &&
              item.name.toLowerCase().includes(this.searchService.toLowerCase())
            );
          }),
          "headline"
        );
      }
    },
    methods: {
      add() {
        this.close();
        this.addItem = new Item({}, this.$parent, this, this.byInsured);
        this.dialog = true;

      },
      edit() {
        this.addItem = new Item(vm2js(this.item), this.$parent, this, this.byInsured);
        this.dialog = true;
      },
      async deleteConfirm() {
        const res = await fetch.post(
          "/api/insurance/programs/delete-limit?id=" + this.item.id
        );
        if (res) {
          const index = this.items.indexOf(this.selected[0]);
          this.items.splice(index, 1);
          this.selected = [];
          this.dialogDelete = false;
          if (this.vm.refresh) this.vm.refresh();
        }
      },
      async save() {
        let res;
        if (this.byInsured) {
          let data = vm2js(this.addItem);
          data.quantity = this.addItem.quantity;
          if (!data.insuredId) {
            data.insuredId = this.vm.vm.item.id;
          }




          res = await fetch.post(
            "/api/insurance/programs/save-limit-by-insured",
            data
          );
          if (res) {
            if (data.limitType === "PC") {
              this.vm.fetchItems(this.vm.vm.item.id).then(() => {
                this.fetchItems(this.vm.selected);
              });
            } else {
              await this.fetchItems(this.vm.vm.item.id);
            }
            this.dialog = false;
            this.vm.refresh();
          }
        } else {
          res = await fetch.post(
            "/api/insurance/programs/save-limit",
            vm2js(this.addItem)
          );
          if (res) {
            if (this.addItem.id) {
              const index = this.items.indexOf(this.selected[0]);
              this.items.splice(index, 1);
              this.selected = [];
            }
            this.addItem.id = res;
            this.items.push(new Item(vm2js(this.addItem), this.$parent, undefined, this.byInsured));
            this.dialog = false;
          }
        }
      },
      close() {
        this.selected = [];
      },
      async fetchItems(parentId) {
        this.items = [];
        this.loading = true;
        let res;
        if (this.byInsured)
          res = await fetch.get(
            "/api/insurance/programs/get-categories-list-by-insured?insuredId=" +
            parentId
          );
        else
          res = await fetch.get(
            "/api/insurance/programs/get-categories-list?programId=" + parentId
          );
        res.forEach(r => {
          const item = new Item(r, this.$parent, undefined, this.byInsured);
          this.items.push(item);
        });
        this.loading = false;
      },
      async fetchServices() {
        this.services = [];
        this.servicesLoading = true;
        let res;
        if (!this.byInsured) {
          res = await fetch.get(
            "/api/insurance/programs/get-services-list?programId=" +
            this.item.programId +
            "&serviceCategoryId=" +
            this.item.serviceCategoryId
          );
        } else {
          res = await fetch.get(
            "/api/insurance/programs/get-services-list-by-insured?insuredId=" +
            this.vm.selected +
            "&serviceCategoryId=" +
            this.item.serviceCategoryId
          );
        }
        const services = ctx.src.services.byCategory(this.item.serviceCategoryId);
        services.forEach(s => {
          let service = res.find(r => {
            return r.serviceId === s.id;
          });
          if (!service) service = {};
          service.name = s.name;
          service.serviceId = s.id;
          service.programId = this.item.programId;
          service.serviceCategoryId = this.item.serviceCategoryId;
          this.services.push(new Service(service, this));
        });
        this.servicesLoading = false;
      },
      rowClick(obj, row) {
        if (!row.isSelected) row.select(obj);
        else this.selected = [];
      },
      limitServices() {


        if (this.addItem.limitCategoryKey === 'N') {
          this.addItem.toAddItems = this.addItem.toAddItems.filter(item => limitToIds.includes(item.id));
        } else this.fetchServices();
      }
    },
    created() {
      this.vm.detail = this;
    },

    mounted() {
    }
  };
</script>

<style lang="scss" scoped>
  .this-wrapper {
    height: auto;
    position: absolute;
    top: 116px;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>
