<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>

    <td>{{ item.additional.categoryName }}</td>
    <td>{{ item.additional.amount }}</td>
    <td>{{ item.additional.limitCategoryName }}</td>
    <td>{{ item.additional.unionNumber }}</td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  })
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
