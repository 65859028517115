var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"listWrapper"}},[(!_vm.mainView)?_c('v-toolbar',{staticClass:"my-bar"},[_c('v-spacer'),_c('ButtonFacade',{attrs:{"icon":"cloud_download","handler":_vm.exp}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1,"single-select":true,"item-key":"id","show-select":"","hide-default-footer":"","loading":_vm.loading,"loading-text":"Загрузка... Пожалуйста подождите","fixed-header":"","item-class":_vm.itemColor},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([(!_vm.mainView)?{key:"item.index",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}}:null,{key:"item.iin",fn:function(ref){
var item = ref.item;
return [(!_vm.mainView)?_c('span',[_vm._v(_vm._s(item.iin))]):_vm._e()]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.startDate)))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateLocalFilter")(item.endDate)))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }